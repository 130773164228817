<template>
    <div class="bg-gray-100 min-h-screen flex justify-center text-gray-800">
        <div class="mt-16 xl:max-w-4xl lg:max-w-2xl md:max-w-xl">
            <h1 class="font-mono text-4xl text-green-500 font-bold bg-black rounded-lg shadow p-2">~/gd/bugs<span class="text-white">$ cat reports.txt</span></h1>
            <h2 class="text-3xl font-bold self-bottom mt-4 text-center">GD Bugs</h2>
            <a href="https://github.com/0x1DEA/gd-bugs/issues/new" class="text-xl underline self-bottom mt-4 text-center block">Submit a Bug</a>
            <div class="flex flex-col mt-2">
                <bug-tag v-for="issue in issues" :name="issue.title" :author="issue.user"
                         :description="issue.body"
                         :tags="issue.labels"
                         :link="issue.html_url"/>
            </div>
        </div>
    </div>

</template>
<script>
import BugTag from "@/Pages/Dash/BugTag";

export default {
    components: {
        BugTag
    },
    data() {
        return {
            issues: []
        }
    },
    beforeMount() {
        fetch('https://api.github.com/repos/0x1DEA/gd-bugs/issues')
            .then(res => res.json())
            .then(data => {
                this.issues = data;
            })
    }
}
</script>
