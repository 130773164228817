<template>
    <div class="bg-gray-100 min-h-screen flex justify-center text-gray-800">
        <div class="mt-16 xl:max-w-4xl lg:max-w-2xl md:max-w-xl">
            <h1 class="font-mono text-2xl font-bold text-white bg-black rounded-lg shadow p-2"><span class="text-green-500">root@yggdrasil</span>:<span class="text-blue-500">~/gd</span>$ cat README.txt</h1>
            <h2 class="text-xl font-bold self-bottom">Friends</h2>
            <p>Dash GD is a collective of GD programmers. etc. etc //TODO: write the rest of this later</p>
            <h2 class="text-xl font-bold self-bottom">Projects</h2>
            <div class="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-2">
                <simple-tag title="GD Bugs" body="GD Bug Tracker" link="https://bugs.dash.gd"/>
                <simple-tag title="GD Mods" body="GD Mod Registry" link="https://mods.dash.gd"/>
                <simple-tag title="GD Textures" body="GD Mod Registry" link="https://mods.dash.gd"/>
            </div>
            <Link href="/" class="text-gray-500 self-bottom mt-4 underline">&lt; Go Back</Link>
        </div>
    </div>
</template>
<script>
import NameTag from "@/Pages/Dash/NameTag";
import SimpleTag from "@/Pages/Dash/SimpleTag";
import {Link} from "@inertiajs/inertia-vue3";

export default {
    components: {SimpleTag, NameTag, Link}
}
</script>
