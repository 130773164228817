<template>
    <div class="bg-gray-100 min-h-screen flex justify-center text-gray-800">
        <div class="mt-16 xl:max-w-4xl lg:max-w-2xl md:max-w-xl">
            <h1 class="font-mono text-4xl text-green-500 font-bold bg-black rounded-lg shadow p-2">~/gd<span class="text-white">$ cat ACKNOWLEDGEMENTS.txt</span></h1>
            <p class="text-center mt-2">Check out these other cool projects made by the programming community!</p>
            <h2 class="text-xl font-bold self-bottom">Friends</h2>
            <div class="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-2">
                <simple-tag title="MyDemonList"/>
                <simple-tag title="GD Browser" link="https://gdbrowser.com"/>
                <simple-tag title="GD History" link="https://gdhistory.geometrydash.eu"/>
                <simple-tag title="Pointercrate" link="https://pointercrate.com"/>
                <simple-tag title="GeoWeb" link="https://geoweb.nem-creator.com"/>
            </div>
            <h2 class="text-xl font-bold self-bottom mt-2">Enemies</h2>
            <div class="flex flex-wrap mt-2">
                <simple-tag title="Crouton" body="crouton." link="https://crouton.net"/>
            </div>
            <Link href="/" class="text-gray-500 self-bottom mt-4 underline">&lt; Go Back</Link>
        </div>
    </div>
</template>
<script>
import NameTag from "@/Pages/Dash/NameTag";
import SimpleTag from "@/Pages/Dash/SimpleTag";
import Link from "@inertiajs/inertia-vue3";

export default {
    components: {Link, SimpleTag, NameTag}
}
</script>
