<template>
    <div class="flex flex-col items-center min-h-screen bg-neutral-800 text-white">
        <div class="flex-col items-center max-w-4xl">
            <img alt="logo" src="./../../images/hyperbolus.png" class="w-1/2"/>
            <h1 class="text-4xl text-center font-bold mt-2">Hyperbolus</h1>
            <p>i am grate web designer</p>
            <a ref="download" class="hover:cursor-pointer p-2 m-8 hover:bg-green-500 bg-cyan-500 rounded shadow-xl" download="hyperbolus_latest.zip">down Load (click)</a>
        </div>
        <div class="bg-neutral-900 grid grid-cols-4">
            <div>
                <p class="float-right font-mono">i dont have aything to prove to you this program is based</p>
            </div>
        </div>
        <div>
            <p>I will insert details here later</p>
        </div>
        <div class="flex justify-center absolute bottom-0 bg-neutral-900 w-screen p-2">
            <span>Bird Cafe &copy; 2022</span>
        </div>
    </div>
</template>
<script>
export default {
    beforeMount() {
        fetch('/api/releases/latest')
        .then(res => res.json())
        .then(data => {
            this.$refs.download.href = data.platforms['windows-x86_64'].url;
        })
    }
}
</script>
