<template>
    <Link v-if="local" :href="link" class="mr-4 inline-block hover:cursor-pointer hover:underline">
        <slot/>
    </Link>
    <a v-else :href="link" class="mr-4 inline-block hover:cursor-pointer hover:underline">
        <slot/>
    </a>
</template>
<script>
import {Link} from "@inertiajs/inertia-vue3";

export default {
    components: {
        Link,
    },
    props: ['link', 'local']
}
</script>
