<template>
    <div class="bg-gray-100 min-h-screen flex justify-center text-gray-800">
        <div class="mt-16 xl:max-w-4xl lg:max-w-2xl md:max-w-xl">
            <h1 class="font-mono text-2xl font-bold text-white bg-black rounded-lg shadow p-2"><span class="text-green-500">root@yggdrasil</span>:<span class="text-blue-500">~/gd</span>$ cat CONTRIBUTORS.txt</h1>
            <div class="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-2">
                <name-tag name="SeeBeyond" roles="sysadmin, backend, frontend" link="https://seebeyond.dev"/>
                <name-tag name="Nora" roles="design, frontend"/>
                <name-tag name="Cvolton" roles="chillin'" link="https://twitter.com/Misabr0penguin"/>
                <name-tag name="Cos8oih" roles="italian" link="https://twitter.com/cos8oih"/>
                <name-tag name="Camila314" roles="macos >:p" link="https://github.com/camila314"/>
                <name-tag name="Matcool" roles="dijo que si" link="https://github.com/matcool"/>
            </div>
            <Link href="/" class="text-gray-500 self-bottom mt-4 underline">&lt; Go Back</Link>
        </div>
    </div>
</template>
<script>
import NameTag from "@/Pages/Dash/NameTag";
import SimpleTag from "@/Pages/Dash/SimpleTag";
import {Link} from "@inertiajs/inertia-vue3";

export default {
    components: {SimpleTag, NameTag, Link}
}
</script>
