<template>
    <a :href="link" class="flex-grow rounded-lg bg-white shadow p-4 m-2">
        <div class="flex flex-row space-x-2">
            <img class="h-full rounded-full" :src="'https://ui-avatars.com/api/?name=' + name + '&background=random'" :alt="name"/>
            <div class="flex flex-col">
                <h1 class="text-xl font-bold">{{ name }}</h1>
                <p>{{ roles }}</p>
            </div>
        </div>
    </a>
</template>
<script>
export default {
    props: ['name', 'roles', 'link']
}
</script>
