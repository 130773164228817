<template>
    <a :href="link" class="flex-grow rounded-lg bg-white shadow p-4 m-2">
        <div class="flex flex-col">
            <div class="flex flex-row">
                <h1 class="text-xl font-bold">{{ name }}</h1>
                <a :href="author.url" class="ml-2 underline text-sm">By: {{ author.login }}</a>
            </div>
            <p>{{ description }}</p>
            <div class="mt-2">
                <a v-for="tag in tags" class="mr-2 py-1 px-2 text-sm rounded bg-gray-100 shadow"
                      :style="'background-color: ' + tag.color">{{ tag.name }}</a>
            </div>
        </div>
    </a>
</template>
<script>
export default {
    props: ['name', 'description', 'author', 'version', 'tags', 'link']
}
</script>
