<template>
    <a :href="link" class="rounded-lg bg-white shadow p-4 m-2">
        <div class="flex flex-col">
            <div class="flex flex-row">
                <h1 class="text-xl font-bold">{{ title }}</h1>
            </div>
            <p>{{ body }}</p>
        </div>
    </a>
</template>
<script>
export default {
    props: ['title', 'body', 'link']
}
</script>
