<template>
    <div class="bg-gray-100 min-h-screen flex justify-center text-gray-800">
        <div class="mt-16 xl:max-w-4xl lg:max-w-2xl md:max-w-xl">
            <h1 class="font-mono text-4xl text-green-500 font-bold bg-black rounded-lg shadow p-2">~/gd/textures<span
                class="text-white">$ cat packs.txt</span></h1>
            <h2 class="text-3xl font-bold self-bottom mt-4 text-center">GD Texture Packs</h2>
            <p>nothing here bud.</p>
        </div>
    </div>
</template>
