<template>
    <div class="bg-gray-100 min-h-screen flex justify-center text-gray-800">
        <div class="mt-16 xl:max-w-4xl lg:max-w-2xl md:max-w-xl">
            <h1 class="font-mono text-4xl text-green-500 font-bold bg-black rounded-lg shadow p-2">~/gd/mods<span
                class="text-white">$ cat list.txt</span></h1>
            <h2 class="text-3xl font-bold self-bottom mt-4 text-center">GD Mods</h2>
            <div class="flex flex-col mt-2">
                <mod-tag name="BetterInfo (NEW!)" author="Cvolton"
                         description="Adds a TON of useful informational utilities throughout the game"
                         :tags="['MinHook', 'Info', 'Standalone']"
                         link="https://github.com/Cvolton/best-epic-gd-mods/releases/download/betterinfo-v2.0.0/betterinfo.zip"/>
                <mod-tag name="Run Info" author="Matcool"
                         description="Adds an overlay to the corner of the screen which shows info for practice mode and start pos attempts"
                         :tags="['MinHook', 'Info', 'Standalone']"
                         link="https://github.com/matcool/small-gd-mods/releases/download/mods/run-info.dll"/>
                <mod-tag name="RGB Color Input" author="Matcool"
                         description="Adds a widget to directly edit the R G and B values of a color channel"
                         :tags="['MinHook', 'Editor', 'Standalone']"
                         link="https://github.com/matcool/small-gd-mods/releases/download/mods/rgb-color-input.dll"/>
                <mod-tag name="Song Search" author="Matcool"
                         description="Allows you to search through your saved songs in the song select dialog"
                         :tags="['MinHook', 'Utility', 'Standalone']"
                         link="https://github.com/matcool/small-gd-mods/releases/download/mods/song-search.dll"/>
                <mod-tag name="Menu Shaders" author="Matcool"
                         description="Replaces the background of the main menu with a custom shader, which can be customized"
                         :tags="['MinHook', 'Cosmetic', 'Standalone']"
                         link="https://github.com/matcool/small-gd-mods/releases/download/mods/menu-shaders.dll"/>
                <mod-tag name="Sum Attempts" author="Matcool"
                         description="Adds a button to the levels screen that adds up all the attempts in that folder"
                         :tags="['MinHook', 'Info', 'Standalone']"
                         link="https://github.com/matcool/small-gd-mods/releases/download/mods/sum-attempts.dll"/>
            </div>
        </div>
    </div>
</template>
<script>
import ModTag from "@/Pages/Dash/ModTag";

export default {
    components: {ModTag}
}
</script>
