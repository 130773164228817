<template>
    <div class="bg-gray-100 min-h-screen flex justify-center items-center text-gray-800">
        <div class="xl:max-w-6xl lg:max-w-4xl md:max-w-2xl">
            <div class="font-mono text-2xl text-white font-bold bg-black rounded-lg p-4 pt-2" style="box-shadow: rgba(0,0,0,.6) 0 15px 30px;">
                <p><span class="text-green-500">root@yggdrasil</span>:<span class="text-blue-500">~/gd</span>$ ls</p>
                <console-link link="/about" local="true">README.txt</console-link>
                <console-link link="/people" local="true">CONTRIBUTORS.txt</console-link>
                <console-link link="/friends" local="true">ACKNOWLEDGEMENTS.txt</console-link>
                <console-link link="https://mods.dash.gd" class="text-blue-500">mods</console-link>
                <console-link link="https://textures.dash.gd" class="text-blue-500">textures</console-link>
                <console-link link="https://bugs.dash.gd" class="text-blue-500">bugs</console-link>
                <console-link link="https://dashlink.net" class="text-cyan-300">dashlink.net</console-link>
                <console-link link="https://gdforums.com" class="text-cyan-300">gdforums.com</console-link>
            </div>
        </div>
        <a href="https://geometry-dash-fan.fandom.com/wiki/Michigun" class="text-3xl text-gray-200 hover:text-gray-400 transition-colors absolute block bottom-0 right-0 py-4 px-8 select-none">▲▲▲</a>
    </div>
</template>
<script>
import ConsoleLink from "@/Pages/Dash/ConsoleLink";
export default {
    components: {ConsoleLink}
}
</script>
