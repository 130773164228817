<template>
    <a :href="link" class="flex-grow rounded-lg bg-white shadow p-4 m-2">
        <div class="flex flex-row space-x-2">
            <img class="rounded-full h-full" :src="'https://ui-avatars.com/api/?name=' + name + '&background=random'" :alt="name"/>
            <div class="flex flex-col">
                <div class="flex flex-row">
                    <h1 class="text-xl font-bold">{{ name }}</h1>
                    <span class="ml-2">By: {{ author }}</span>
                </div>
                <p>{{ description }}</p>
                <div class="mt-2">
                    <span v-for="tag in tags" class="mr-2 py-1 px-2 text-sm rounded bg-gray-100 shadow">{{ tag }}</span>
                </div>
            </div>
        </div>
    </a>
</template>
<script>
export default {
    props: ['name', 'description', 'author', 'version', 'tags', 'link']
}
</script>
